export const lt = {
    'invoice': {
        'invoice': 'Sąskaitos numeris:',
        'sum-invoice': 'Sąskaitos suma:',
        'select-invoice': 'Pasirinkite mokėjimo valiutą',
        'coin-network': 'Valiuta ir tinklas:',
        'rate': 'Na:',
        'sum-payment': 'Mokėjimo suma:',
        'button': 'Mokėjimas',
        'search': 'Paieška',
    },
    'checkout': {
        'shop': 'Rezultatas',
        'payment': 'Mokėjimas',
        'back_to_the_store': 'Atgal į parduotuvę',
        'to_make_a_payment': 'Siųskite «{orderCurrency}» tinkle «{orderAlias}» , kad atliktumėte mokėjimą nurodytu adresu',
        'payment_completed': 'Mokėjimas baigtas',
        'the_payment_is_invalid': 'Mokėjimas negalioja',
        'payment_rejected': 'Mokėjimas atmestas',
        'payment_pending': 'Mokėjimas apdorojimo metu',
        'payment_init': 'Laukiama apmokėjimo',
        'lifetime_has_expired': 'Baigėsi mokėjimo terminas. Nesiųskite monetų nurodytu adresu.',
        'contact_the_store_owner': 'Baigėsi mokėjimo terminas. Nesiųskite monetų nurodytu adresu. Norėdami grąžinti monetas arba tęsti mokėjimą, susisiekite su parduotuvės savininku',
        'order': 'Įsakymas',
        'to_be_paid': 'Mokėti',
        'received': 'Gauta:',
        'description': 'Apibūdinimas:',
        'before_the_expiration_of_the_order': 'Mokėjimo galiojimo laikas:',
        'network': 'Tinklas:',
        'payment_address': 'Mokėjimo adresas:',
        'payment_address_not_found': 'Mokėjimo adresas nerastas',
        'tag': 'Žyma:',
        'contract_address': 'Sutarties adresas:',
        'send_to_this_address_only': 'Siųsti tik šiuo adresu',
        'make_sure_the_network_is_correct': 'Patikrinkite, ar tinklas tinkamas:',
        'network_is_correct': 'Patikrinkite, ar tinklas tinkamas:',
        'contract_address_matches': 'Įsitikinkite, kad sutarties adresas sutampa',
        'payment_page': 'Mokėjimo puslapis',
        'the_address_was_copied': 'Adresas sėkmingai nukopijuotas',
        'the_tag_was_copied': 'Žyma nukopijuota',
        'invoice_was_successfully_paid': 'Užsakymas buvo sėkmingai apmokėtas',
        'the_invoice_was_paid': 'Užsakymas buvo apmokėtas',
        'important_info': 'Svarbi informacija',
        'the_contract_address_does_not_exist_on_this_network': 'Sutarties adresas šiame tinkle neegzistuoja',
        'error': 'Klaida',
        'time_hours': 'valandų',
        'time_minute': 'minučių',
        'time_second': 'sekundžių',
        'sellers_address': 'Pardavėjo adresas patvirtintas',
        'stored_table': 'Čia bus gaunamų operacijų istorija',
        'history_table': 'Įeinančių sandorių istorija',
        'full_info': 'Rodyti visą informaciją',
        'table_date': 'Data',
        'table_sum': 'Suma',
        'table_value': 'Valiuta',
        'table_hash': 'tx_hash',
        'table_date_sum': 'Suma ir data',
        'payment_partially_paid': 'Iš dalies sumokėta',
        'paid_timer': 'Sumokėta',
        'expired_timer': 'Baigėsi mokėjimas',
    },
    'new_checkout': {
        'details': {
            'title': 'Detalės',
            'order': 'Įsakymas',
            'date': 'Data',
            'description': 'Apibūdinimas',
        },
        'notification': 'Prašome siųsti tik <b>{orderCurrency}</b> žetonus <b>{orderNetwork}</b> tinkle į šį adresą.',
        'payment_address': 'Mokėjimo adresas',
        'verified_notification': "Pardavėjo adresas buvo patikrintas",
        'paid_amount': "Sumokėta suma",
        'timer_notifications': {
            'remaining_time': 'Likęs laikas mokėti',
            'completed': 'Mokėjimas buvo sėkmingai baigtas',
            'expired': 'Mokėjimo gyvenimo laikas pasibaigė',
        },
        'statuses': {
            'not_paid': 'Nesumoketas',
            'overpaid': 'Permokėta',
            'partially_paid': 'Iš dalies mokama',
            'fully_paid': 'Visiškai sumokėta',
        },
        'history': {
            'title': 'Mokėjimų istorija',
            'columns': {
                'date': 'Data',
                'amount': 'Suma',
                'tx_hash': 'Tx_hash'
            },
            'empty_notification': 'Šiame lange bus galima rasti visas jūsų operacijas, išsiųstas nurodytu adresu.',
            'ok': 'ОК'
        },
        'orphan': {
            'title': 'Bāreņu darījums',
            'description': 'Viens no šī pasūtījuma darījumiem satur nepareizu tīklu vai valūtu.',
            'contact': 'Sazinieties ar tehnisko atbalstu, izmantojot ID',
        },
        'time-is-over': 'Laiks ir beidzies',
        'payment-successful': 'Maksājums sekmīgs',
        'partial-desc': 'Summa ir daļēji samaksāta. atlikušā summa jāsamaksā par veiksmīgu maksājumu.',
        'cancel': 'Atšaukti',
        'back_to_shop': 'Atgal į parduotuvę'
    },
    'new_invoice': {
        'details': {
            title: 'Detalės',
            invoice: 'Sąskaita faktūra',
            date: 'Data',
            description: 'Apibūdinimas'
        },
        'amount': 'Suma',
        'btn_label': 'Mokėkite su {selectedCurrencyNetwork}',
        'btn_label_empty': 'Pasirinkite valiutą',
        'cancel': 'Atšaukti',
        'search': 'Paieška...',
    },
    'languages': {
        'select': 'Pasirinkite kalbą'
    },
    'preCheckout': {
        'errors': {
            'page_not_found': 'Puslapis nerastas',
            'page_not_found_text': 'Atrodo, kad nerandame ieškomo puslapio',
        }
    }
};
