export const kr = {
    'invoice': {
        'invoice': '계좌 번호:',
        'sum-invoice': '송장 금액:',
        'select-invoice': '결제 통화 선택',
        'coin-network': '통화 및 네트워크:',
        'rate': '코스:',
        'sum-payment': '지불 금액:',
        'button': '지불',
        'search': '찾다',
    },
    'checkout': {
        'shop': '가게',
        'payment': '지불',
        'back_to_the_store': '스토어로 돌아가기',
        'to_make_a_payment': '결제하려면 네트워크 «{orderAlias}»에서 «{orderCurrency}»를 보내십시오.지정된 주소로',
        'payment_completed': '결제 실행',
        'the_payment_is_invalid': '결제가 유효하지 않습니다.',
        'payment_rejected': '결제 거부',
        'payment_pending': '결제 진행 중',
        'payment_init': '결제 대기 중',
        'lifetime_has_expired': '결제 기간이 만료되었습니다. 지정된 주소로 동전을 보내지 마십시오.',
        'contact_the_store_owner': '결제 기간이 만료되었습니다. 지정된 주소로 동전을 보내지 마십시오.코인 환불 또는 계속 지불을 위해 상점 소유자에게 문의하십시오.',
        'order': '영장',
        'to_be_paid': '지불하기',
        'received': '받은:',
        'description': '묘사:',
        'before_the_expiration_of_the_order': '지불 기간:',
        'network': '그물:',
        'payment_address': '수취인 주소:',
        'payment_address_not_found': '수취인 주소를 찾을 수 없습니다',
        'tag': '태그:',
        'contract_address': '계약 주소:',
        'send_to_this_address_only': '이 주소로만 보내기',
        'make_sure_the_network_is_correct': '네트워크가 올바른지 확인합니다.',
        'network_is_correct': '네트워크가 올바른지 확인합니다.',
        'contract_address_matches': '계약 주소가 일치하는지 확인',
        'payment_page': '결제 페이지',
        'the_address_was_copied': '주소가 성공적으로 복사되었습니다.',
        'the_tag_was_copied': '태그가 복사되었습니다.',
        'invoice_was_successfully_paid': '주문이 성공적으로 결제되었습니다.',
        'the_invoice_was_paid': '주문이 금액에 대해 지불되었습니다.',
        'important_info': '중요 정보',
        'the_contract_address_does_not_exist_on_this_network': '계약 주소가 이 네트워크에 없습니다.',
        'error': '오류',
        'time_hours': '시간',
        'time_minute': '분',
        'time_second': '초',
        'sellers_address': '판매자 주소가 확인되었습니다.',
        'stored_table': '들어오는 거래의 기록은 다음과 같습니다.',
        'history_table': '들어오는 거래의 역사',
        'full_info': '전체 세부 정보 표시',
        'table_date': '날짜',
        'table_sum': '합계',
        'table_value': '통화',
        'table_hash': 'tx_hash',
        'table_date_sum': '금액 및 날짜',
        'payment_partially_paid': '부분 지급',
        'paid_timer': '지급',
        'expired_timer': '결제가 만료되었습니다',
    },
    'new_checkout': {
        'details': {
            'title': '세부',
            'order': '주문하다',
            'date': '날짜',
            'description': '설명',
        },
        'notification': '<b>{orderNetwork}</b> 네트워크에서 <b>{orderCurrency}</b> 토큰만이 주소로 보내십시오.',
        'payment_address': '지불 주소',
        'verified_notification': "판매자의 주소가 확인되었습니다",
        'paid_amount': "유료 금액",
        'timer_notifications': {
            'remaining_time': '지불 할 남은 시간',
            'completed': '지불이 성공적으로 완료되었습니다',
            'expired': '지불 수명이 만료되었습니다',
        },
        'statuses': {
            'not_paid': '미 지불 상태의',
            'overpaid': '초과 지불',
            'partially_paid': '부분적으로 지불',
            'fully_paid': '전액 지불',
        },
        'history': {
            'title': '지불 기록',
            'columns': {
                'date': '날짜',
                'amount': '양',
                'tx_hash': 'Tx_hash'
            },
            'empty_notification': '지정된 주소로 전송 된 모든 거래는이 창에서 제공됩니다.',
            'ok': '좋아요'
        },
        'orphan': {
            'title': '고아 거래',
            'description': '이 주문의 거래 중 하나에 잘못된 네트워크나 통화가 포함되어 있습니다.',
            'contact': '다음 ID로 기술 지원팀에 문의하세요.',
        },
        'time-is-over': '시간이 끝났어',
        'payment-successful': '결제 성공',
        'partial-desc': '금액이 일부 결제되었습니다. 성공적인 결제를 위해서는 나머지 금액을 결제해야 합니다.',
        'cancel': '취소',
        'back_to_shop': '가게로 돌아갑니다'
    },
    'new_invoice': {
        'details': {
            title: '세부',
            invoice: '송장',
            date: '날짜',
            description: '설명'
        },
        'amount': '양',
        'btn_label': '{selectedCurrencyNetwork} 으로 지불하십시오',
        'btn_label_empty': '통화를 선택하십시오',
        'cancel': '취소',
        'search': '찾다...',
    },
    'languages': {
        'select': '언어를 선택하십시오'
    },
    'preCheckout': {
        'errors': {
            'page_not_found': '페이지를 찾을 수 없습니다',
            'page_not_found_text': '찾고있는 페이지를 찾을 수없는 것 같습니다.',
        }
    }
};
